@import url('https://fonts.googleapis.com/css2?family=Instrument+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://api.fontshare.com/v2/css?f[]=satoshi@1,900,700,500,301,701,300,501,401,901,400,2&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --forest-deep: #051510;
  --forest-mid: #0B291E;
  --forest-light: #0F3C2D;
}

.bg-forest-gradient {
  background-image: radial-gradient(circle at 50% 50%, var(--forest-light) 0%, var(--forest-mid) 35%, var(--forest-deep) 100%);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Add these animation classes */
.fade-enter {
  opacity: 0;
  transform: translateY(10px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 300ms, transform 300ms;
}

.spin-button {
  transform: rotate(90deg);
  transition: transform 300ms ease;
}

.spin-button:hover {
  transform: rotate(450deg);
}

@keyframes fall {
  0% {
    transform: translateY(-100vh) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translateY(80vh) rotate(360deg);
    opacity: 1;
  }
}

.money-particle {
  position: fixed;
  top: -20px;
  animation: fall linear forwards;
  z-index: 50;
  pointer-events: none;
  opacity: 1;
}
